<template>
  <div class="ielts">
    <!-- <img
      src="../../assets/image/ielts/bg.png"
      style="height: 440px; width: 100vw"
      alt=""
    /> -->
    <myimage :url="url"></myimage>
    <div class="content2">
      <div class="pinfen">
        <div class="title">
          <div class="service-con">
            <div class="servicetop">{{$t('ielts.评分标准')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="fenshu">{{$t('ielts.总分')}}</div>
        <div class="img">
          <img src="../../assets/image/ielts/pingfen.png" />
        </div>
      </div>
      <div class="moshi">
        <div class="title">
          <div class="service-con">
            <div class="servicetop">{{$t('ielts.选择模式')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/image/ielts/moshi.png" />
        </div>
      </div>
      <div class="kaoshi">
        <div class="title">
          <div class="service-con">
            <div class="servicetop">{{$t('ielts.考试模式')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/image/ielts/kaoshi.png" />
        </div>
      </div>
      <div class="neirong">
        <div class="title">
          <div class="service-con">
            <div class="servicetop">{{$t('ielts.考试内容')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/image/ielts/neirong.png" />
        </div>
      </div>
    </div>
    <div class="bgc af6">
      <div class="content3">
        <div class="liucheng">
          <div class="title">
            <div class="service-con">
              <div class="servicetop">{{$t('ielts.考试流程')}}</div>
              <div class="line2"></div>
            </div>
          </div>
          <div class="img">
            <img src="../../assets/image/ielts/liucheng.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="chengxu">
        <div class="title">
          <div class="service-con">
            <div class="servicetop">{{$t('ielts.报名程序')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/image/ielts/chengxu.png" />
        </div>
      </div>
      <div class="fuwu">
        <div class="title">
          <div class="service-con">
            <div class="servicetop">{{$t('ielts.考后服务')}}</div>
            <div class="line2"></div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/image/ielts/fuwu.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
  export default {
    components:{
      myimage
    },
    data() {
      return {
        url:require('../../assets/image/ielts/bg.png')
      };
    },
  };
</script>

<style lang="scss">
  .ielts {
    .f7 {
      background-color: #f7f7f7;
    }
    .af6 {
      background-color: #fefaf6;
      padding-bottom: 50px;
    }
    .bgc {
      width: 100%;
      padding-top: 1px;

      .content3 {
        width: 1176px;
        margin: 0 auto;
        .liucheng {
          .img {
            margin: 50px 0 70px 0;
            > img {
              width: 1176px;
              height: 1099px;
            }
          }
        }
      }
    }
    .content2 {
      width: 1176px;
      margin: 0 auto;
      .fuwu {
        .img {
          margin: 50px 0 70px 0;
          > img {
            width: 1176px;
            height: 1259px;
          }
        }
      }
      .chengxu {
        .img {
          margin: 50px 0 70px 0;
          > img {
            width: 1176px;
            height: 813px;
          }
        }
      }
      .neirong {
        .img {
          margin: 50px 0;
          > img {
            width: 1176px;
            height: 958px;
          }
        }
      }
      .kaoshi {
        .img {
          margin: 50px 0;
          > img {
            width: 1176px;
            height: 171px;
          }
        }
      }
      .moshi {
        .img {
          margin: 50px 0;
          > img {
            width: 1019px;
            height: 264px;
          }
        }
      }
      .pinfen {
        .img {
          margin: 50px 0;
          > img {
            width: 1176px;
            height: 1263px;
          }
        }
        .fenshu {
          font-size: 28px;
          font-weight: 400;
          color: #fe992b;
          left: 520px;
          bottom: 20px;
        }
      }
    }
    .title {
      position: relative;
      height: 100px;
      margin-top: 50px;

      .english-yellow {
        color: #fe992b;
        font-size: 44px;
        opacity: 0.3;
      }

      .service-con {
        width: 100%;
        position: absolute;
        top: 17px;
      }

      .line2 {
        width: 124px;
        height: 6px;
        border-radius: 3px;
        background-color: #fe992b;
        margin: 0 auto;
      }

      .servicetop {
        // width: 160px;
        font-size: 32px;
        color: #333;
        font-weight: bold;
        padding: 10px 0;
        // border-bottom: 6px solid #fe992b;
        border-radius: 3px;
        margin: 0 auto;
      }
    }
  }
</style>
